
import { useState } from 'react';
import { FaInstagram, FaLinkedin, FaFacebook, FaGithub, FaUser, FaTelegram } from 'react-icons/fa';
import { ReactComponent as TwitterXIcon } from './twitter-x.svg';
import profileImage from './0xday.png'; // Adjust the path as needed


function App() {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center text-white" style={{ background: 'linear-gradient(to bottom, #00FF00, #000000)' }}>
      {/* Content Section */}
      <div className="relative z-20 flex flex-col items-center justify-center min-h-screen px-4 lg:px-8">
      <a href='https://0x.day' target='__blank'><img
          src={profileImage}
          alt="Profile"
          className="w-36 h-36 lg:w-36 lg:h-36 rounded-full mx-auto mb-4 shadow-lg"
        /></a>
        
        <h1 className="text-white text-md sm:text-md lg:text-xl font-bold mb-8 text-center">
          BUIDLng SkillProtocol <a href="https://0xHacking.com" target='__blank' className="text-[#4338ca] hover:underline">0xHacking.com</a> - <a href="https://0xprofile.com/" target='__blank' className="text-[#4338ca] hover:underline">0xProfile.com</a> - <a href="https://0xgraph.com/" target='__blank' className="text-[#4338ca] hover:underline">0xGraph.com</a> 
          <span className="block mt-2">🌐 Tech Conferences | 📹 Webinars | 👨‍💻 Hackathons </span> <span></span>
          <span className='block mt-2'> Skill Protocol - <a href="https://skillprotocol.com/" target='__blank'  className="text-[#4338ca] hover:underline">SkillProtocol.com</a></span>
        </h1>

        <div className="flex flex-col w-full lg:w-1/1 space-y-4">
          <a href="https://www.instagram.com/0x.Day" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-red-300 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
            <FaInstagram className="mr-2" /> Instagram
          </a>
          <a href="https://www.linkedin.com/company/0xday" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-blue-300 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
            <FaLinkedin className="mr-2" /> Linkedin
          </a>
          <a href="https://twitter.com/_0xDay" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-gray-300 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
          <TwitterXIcon className="mr-2 h-4 w-4" /> X
          </a>
          <a href="https://www.facebook.com/Tech0xDay" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-blue-400 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
            <FaFacebook className="mr-2" /> Facebook
          </a>
          <a href="https://github.com/0x-Day" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-gray-400 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
            <FaGithub className="mr-2" /> GitHub
          </a>
          <a href="https://t.me/+m3JzPYZh4aJkZjA1" target='__blank' className="w-full py-3 px-4 border border-white rounded-full flex items-center justify-center text-lg hover:bg-blue-400 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
            <FaTelegram className="mr-2" /> Telegram
          </a>
          <a href="https://0x.day/" target='__blank' className="w-full py-3 px-4 border border-white text-white rounded-full flex items-center justify-center text-lg hover:bg-blue-400 hover:text-black transform hover:scale-105 transition-transform duration-300 shadow-lg hover:shadow-xl">
          <strong>Visit 0x.Day</strong>
          </a>
        </div>
      </div>

      {/* Popup */}
      {showPopup && (
        <div className="hidden lg:block fixed top-4 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg z-30">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FaUser className="text-xl mr-2" />
              <span>Create your <strong>0xDay</strong> 0xprofile now!</span>
            </div>
            <button onClick={handleClosePopup} className="ml-4 text-gray-400 hover:text-white">&times;</button>
          </div>
          <a href="https://0xprofile.com/" target='__blank' className="mt-2 block text-center bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg">
            Create Profile
          </a>
        </div>
      )}
    </div>
  );
}

export default App;





